.App {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  &--contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.17em;
    margin: 1em;

    p {
      margin: 0;
    }
  }

  &--all {
    display: flex;

    &--players {
      margin: 2em;
    }
  }

  &--startGame {
    height: 100%;
    align-self: flex-end;
    flex-grow: 1;
    display: flex;
    margin-bottom: 2em !important;
    margin-right: 2em !important;

    &--button {
      height: 3em;
      align-self: flex-end;
    }
  }
}
