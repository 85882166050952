.Players {
  width: 30em;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

  &--player {
    word-wrap: break-word !important;
  }

  &--add {
    margin-bottom: 2em;
    display: flex;
    justify-content: flex-start;
    height: 4em;

    &--input {
      margin-right: 2em !important;
    }

    &--button {
      height: 4em;
    }
  }
}
